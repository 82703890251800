// extracted by mini-css-extract-plugin
export var blockOne = "Register-module--blockOne--eg8tK";
export var blockTwo = "Register-module--blockTwo--ShacG";
export var blocks = "Register-module--blocks--5j-AY";
export var button = "Register-module--button--1oGpH";
export var circle = "Register-module--circle--v6QBm";
export var container = "Register-module--container--9ps8O";
export var done = "Register-module--done--p0QgS";
export var error = "Register-module--error--L3Orp";
export var errors = "Register-module--errors--7QnuZ";
export var firstName = "Register-module--firstName--mfHg9";
export var form = "Register-module--form--cSESh";
export var heading = "Register-module--heading--Ds-VR";
export var icon = "Register-module--icon--UjRFE";
export var input = "Register-module--input--aKI1r";
export var label = "Register-module--label--Q-cGw";
export var lastName = "Register-module--lastName--Tfiri";
export var list = "Register-module--list--lkdvM";
export var listItem = "Register-module--listItem--Arfm4";
export var name = "Register-module--name--UbTKm";
export var paragraph = "Register-module--paragraph--GySA0";
export var register = "Register-module--register--XxfE8";
export var title = "Register-module--title--jtvZY";
export var top = "Register-module--top--rbad3";