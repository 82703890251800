import React from "react";

import { Helmet } from "react-helmet";

import { MouseEvent } from "react";

import clsx from "clsx";

import { navigate } from "gatsby"

import { createRef } from "react";
import { useLayoutEffect } from "react";
import { useState } from "react";

import Box from "@js/component/Box";
import Button from "@js/component/Button";
import CheckIcon from "@js/component/icon/CheckIcon";
import Heading from "@js/component/Heading";
import Input from "@js/component/Input";
import Main from "@js/component/Main";

import { isLoggedIn } from "@js/services/auth";

import * as classNames from "@css/component/template/portal/Register.module.scss";

/**
 * @type MetaProps
 */
type MetaProps = {
    pageContext: {
        metaTitle: string;
        metaDescription: string;
        metaKeywords: string;
        metaUrl: string;
        title_h1: string;
    }
}

/**
 * @const Register
 */
const Register = (props: MetaProps) => {
    const {
        pageContext
    } = props;

    const formRef = createRef<HTMLFormElement>();

    const [companyName, setCompanyName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    const [isSending, setIsSending] = useState(false);
    const [isDone, setIsDone] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: string[]; }>({});

    const onButtonClick = async (event: MouseEvent) => {
        event.preventDefault();

        if (formRef.current?.reportValidity()) {
            setIsSending(true);

            const response = await fetch(`${ process.env.GATSBY_API_PUBLIC_URL }/advisor`, {
                body: JSON.stringify({
                    companyName: companyName,
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    phoneNumber: phoneNumber
                }),
                method: "POST"
            });

            const data = await response.json();

            if (data.status == "failed") {
                setErrors(data.errors);
            }
            else {
                typeof window == "object" && window.gtag && window.gtag("event", "new_advisor");

                setIsDone(true);
            }

            setIsSending(false);
        }
    };

    useLayoutEffect(() => {
        if (isLoggedIn()) {
            navigate("/portaal/dashboard", { replace: true });
        }
    }, []);

    return (
        <Main>
            <Helmet>
                <title>{ pageContext.metaTitle ? pageContext.metaTitle : "Aanmelden" }</title>
                { pageContext.metaDescription && <meta content={pageContext.metaDescription} name="description" /> }
                { pageContext.metaDescription && <meta content={pageContext.metaDescription} property="og:description" /> }
                { pageContext.metaTitle && <meta content={pageContext.metaTitle} property="og:title" /> }
                { pageContext.metaKeywords && <meta content={pageContext.metaKeywords} property="og:keywords" /> }
                { pageContext.metaUrl && <meta content={pageContext.metaUrl} property="og:url" /> }
                <meta content="woninglabel.nl" property="og:site_name" />
            </Helmet>
            <div className={ classNames.container }>
                <div className={ classNames.register }>
                    <Heading className={ classNames.heading } element="h1" variant="large">
                        { pageContext.title_h1 ? pageContext.title_h1 : 'Meld je aan als energielabel-adviseur' }
                    </Heading>
                    <div className={ classNames.blocks }>
                        <div className={ classNames.blockOne }>
                            <Box>
                                { !(isDone) &&
                                    <form className={ classNames.form } ref={ formRef }>
                                        <label className={ classNames.label } htmlFor="company_name">Bedrijfsnaam</label>
                                        <Input
                                            className={ clsx(classNames.input, {
                                                [classNames.error]: errors.companyName
                                            }) }
                                            id="company_name"
                                            name="company_name"
                                            onChange={ (event) => setCompanyName(event.currentTarget.value) }
                                            value={ companyName }
                                        />

                                        <ul className={ classNames.errors }>
                                            { errors.companyName &&
                                            errors.companyName.map((error, index) => (
                                                <li className={ classNames.error } key={ index }>{ error }</li>
                                            )) }
                                        </ul>

                                        <div className={ classNames.name }>
                                            <div className={ classNames.firstName }>
                                                <label className={ classNames.label } htmlFor="first_name">Voornaam</label>
                                                <Input
                                                    className={ clsx(classNames.input, {
                                                        [classNames.error]: errors.firstName
                                                    }) }
                                                    id="first_name"
                                                    name="first_name"
                                                    onChange={ (event) => setFirstName(event.currentTarget.value) }
                                                    value={ firstName }
                                                />
                                            </div>

                                            <div className={ classNames.lastName }>
                                                <label className={ classNames.label } htmlFor="last_name">Achternaam</label>
                                                <Input
                                                    className={ clsx(classNames.input, {
                                                        [classNames.error]: errors.lastName
                                                    }) }
                                                    id="last_name"
                                                    name="last_name"
                                                    onChange={ (event) => setLastName(event.currentTarget.value) }
                                                    value={ lastName }
                                                />
                                            </div>
                                        </div>

                                        <ul className={ classNames.errors }>
                                            { errors.firstName &&
                                            errors.firstName.map((error, index) => (
                                                <li className={ classNames.error } key={ index }>{ error }</li>
                                            )) }
                                            { errors.lastName &&
                                            errors.lastName.map((error, index) => (
                                                <li className={ classNames.error } key={ index }>{ error }</li>
                                            )) }
                                        </ul>

                                        <label className={ classNames.label } htmlFor="email">E-mailadres</label>
                                        <Input
                                            className={ clsx(classNames.input, {
                                                [classNames.error]: errors.email
                                            }) }
                                            id="email"
                                            name="email"
                                            onChange={ (event) => setEmail(event.currentTarget.value) }
                                            type="email"
                                            value={ email }
                                        />

                                        <ul className={ classNames.errors }>
                                            { errors.email &&
                                            errors.email.map((error, index) => (
                                                <li className={ classNames.error } key={ index }>{ error }</li>
                                            )) }
                                        </ul>

                                        <label className={ classNames.label } htmlFor="phone_number">Telefoonnummer</label>
                                        <Input
                                            className={ clsx(classNames.input, {
                                                [classNames.error]: errors.phoneNumber
                                            }) }
                                            id="phone_number"
                                            name="phone_number"
                                            onChange={ (event) => setPhoneNumber(event.currentTarget.value) }
                                            type="text"
                                            value={ phoneNumber }
                                        />

                                        <ul className={ classNames.errors }>
                                            { errors.phoneNumber &&
                                              errors.phoneNumber.map((error, index) => (
                                                <li className={ classNames.error } key={ index }>{ error }</li>
                                            )) }
                                        </ul>

                                        <Button
                                            className={ classNames.button }
                                            disabled={ isSending }
                                            onClick={ onButtonClick }
                                        >
                                            { !(isSending) && "Nu aanmelden" }
                                            { isSending && "Bezig met aanmelden..." }
                                        </Button>
                                    </form>
                                }
                                { isDone &&
                                    <div className={ classNames.done }>
                                        Je bent succesvol aangemeld als energielabel-adviseur!
                                        In je mailbox vind je een e-mail met daarin de gegevens om in te loggen.
                                    </div>
                                }
                            </Box>
                        </div>
                        <div className={ classNames.blockTwo }>
                            <Box>
                                <Heading className={ classNames.heading } element="h2" variant="medium">
                                    Alle voordelen op een rij
                                </Heading>
                                <ul className={ classNames.list }>
                                    <li className={ classNames.listItem }>
                                        <div className={ classNames.top }>
                                            <span className={ classNames.circle }>
                                                <CheckIcon className={ classNames.icon } />
                                            </span>
                                            <div className={ classNames.title }>Meer omzet</div>
                                        </div>
                                    </li>
                                    <li className={ classNames.listItem }>
                                        <div className={ classNames.top }>
                                            <span className={ classNames.circle }>
                                                <CheckIcon className={ classNames.icon } />
                                            </span>
                                            <div className={ classNames.title }>Meer bereik</div>
                                        </div>
                                    </li>
                                    <li className={ classNames.listItem }>
                                        <div className={ classNames.top }>
                                            <span className={ classNames.circle }>
                                                <CheckIcon className={ classNames.icon } />
                                            </span>
                                            <div className={ classNames.title }>Gemak</div>
                                        </div>
                                    </li>
                                    <li className={ classNames.listItem }>
                                        <div className={ classNames.top }>
                                            <span className={ classNames.circle }>
                                                <CheckIcon className={ classNames.icon } />
                                            </span>
                                            <div className={ classNames.title }>Waardering</div>
                                        </div>
                                    </li>
                                    <li className={ classNames.listItem }>
                                        <div className={ classNames.top }>
                                            <span className={ classNames.circle }>
                                                <CheckIcon className={ classNames.icon } />
                                            </span>
                                            <div className={ classNames.title }>Marktkennis</div>
                                        </div>
                                    </li>
                                    <li className={ classNames.listItem }>
                                        <div className={ classNames.top }>
                                            <span className={ classNames.circle }>
                                                <CheckIcon className={ classNames.icon } />
                                            </span>
                                            <div className={ classNames.title }>Echte opdrachten</div>
                                        </div>
                                    </li>
                                </ul>
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        </Main>
    );
};

export default Register;
